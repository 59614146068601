/* eslint-disable @typescript-eslint/naming-convention */
// Portfolio list page
export const SAMPLE_PORTFOLIO_UID = 'sample-portfolio';

// Fibre list page
export const NUM_FIBRES_PER_PAGE = 30;
export const UNKNOWN_LOCATION_NAME = 'Unknown';

// Dates
export const DATE_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
};

// Local storage keys
export const LOCAL_STORAGE_KEY_PORTFOLIO_UPLOAD_PAGE_ONBOARDING =
  'portfolio_upload_page_onboarding_complete';

export const LOCAL_STORAGE_KEY_PORTFOLIO_DASHBOARD_ONBOARDING =
  'portfolio_dashboard_onboarding_complete';

export const SESSION_STORAGE_KEY_HAS_ACCEPTED_TERMS = 'has_accepted_terms';
