import { createAction, props } from '@ngrx/store';
import {
  Portfolio,
  PortfolioOverviewItem,
  PortfolioPriorityFibre,
  PortfolioPriorityFiberByCat,
  PortfolioPriorityFiberByType,
} from '@app/portfolios/models/portfolio.model';

export const layoutPage = {
  fetchPortfolioDetails: createAction(
    '[Layout page] Fetch portfolio details',
    props<{ uid: string }>()
  ),
};

export const portfolioDetailsPage = {
  fetchPortfolioDetails: createAction(
    '[Portfolio details page] Fetch portfolio details',
    props<{ uid: string }>()
  ),
};

export const fibreListPage = {
  fetchPriorityFibres: createAction(
    '[Fibre list page] Fetch priority fibres',
    props<{ uid: string }>()
  ),
};

export const portfolioDetailsEffects = {
  fetchPriorityFibres: createAction(
    '[Portfolio details effects] Fetch priority fibres',
    props<{ uid: string }>()
  ),
  fetchOverview: createAction(
    '[Portfolio details effects] Fetch overview',
    props<{ uid: string }>()
  ),
};

export const portfoliosAPI = {
  fetchPortfolioDetailsSuccess: createAction(
    '[Portfolios API] Fetch portfolio details success',
    props<{ portfolio: Portfolio }>()
  ),
  fetchPortfolioDetailsFailed: createAction(
    '[Portfolios API] Fetch portfolio details failed',
    props<{ error: string }>()
  ),
  fetchPriorityFibresSuccess: createAction(
    '[Portfolios API] Fetch priority fibres success',
    props<{ priorityFibres: PortfolioPriorityFiberByCat[] }>()
  ),
  fetchPriorityFibresFailed: createAction(
    '[Portfolios API] Fetch priority fibres failed',
    props<{ error: string }>()
  ),
  fetchPriorityFibresByCatSuccess: createAction(
    '[Portfolios API] Fetch priority fibres by category success',
    props<{ priorityFibresByCat: PortfolioPriorityFiberByType[] }>()
  ),
  fetchPriorityFibresByCatFailed: createAction(
    '[Portfolios API] Fetch priority fibres  by category failed',
    props<{ error: string }>()
  ),
  fetchOverviewSuccess: createAction(
    '[Portfolios API] Fetch overview success',
    props<{ overview: PortfolioOverviewItem[] }>()
  ),
  fetchOverviewFailed: createAction(
    '[Portfolios API] Fetch overview failed',
    props<{ error: string }>()
  ),
  updateFibreCountsSuccess: createAction(
    '[Portfolios API] Update fibre counts success',
    props<{ portfolio: Portfolio }>()
  ),
  updateFibreCountsFailed: createAction(
    '[Portfolios API] Update Fibre counts failed',
    props<{ error: string }>()
  ),
};

export const portfolioListEffects = {
  renamePortfolio: createAction(
    '[Portfolio list effects] Rename portfolio (portfolio details)',
    props<{ portfolio: Portfolio }>()
  ),
};

export const fibreListEffects = {
  refetchPriorityFibres: createAction(
    '[Fibre list effects] Refetch priority fibres',
    props<{ uid: string }>()
  ),
  clearOverview: createAction('[Fibre list effects] Clear overview'),
  updateFibreCounts: createAction(
    '[Fibre list effects] Update fibre counts (portfolio details)'
  ),
};

export const fibreDetailsEffects = {
  clearPriorityFibres: createAction(
    '[Fibre details effects] Clear priority fibres'
  ),
  clearOverview: createAction('[Fibre details effects] Clear overview'),
  updateFibreCounts: createAction(
    '[Fibre details effects] Update fibre counts (portfolio details)'
  ),
};

export const authEffects = {
  reset: createAction('[Auth effects] Reset portfolio details state'),
};
