import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(): Observable<boolean> {
    return combineLatest([
      this.authService.isSignedIn$,
      this.authService.isEmailVerified$,
      this.authService.isWhitelisted$,
    ]).pipe(
      take(1),
      map(([isSignedIn, isEmailVerified, isWhitelisted]) => {
        const isAllowed = isSignedIn && isEmailVerified && isWhitelisted;

        if (isAllowed) {
          return true;
        } else {
          this.router.navigateByUrl('/signin');
          return false;
        }
      })
    );
  }
}
