import { createSelector } from '@ngrx/store';
import { Portfolio } from '@app/portfolios/models/portfolio.model';
import { selectPortfoliosFeatureState } from '../store.state';

export const selectPortfolioListState = createSelector(
  selectPortfoliosFeatureState,
  portfoliosFeatureState => portfoliosFeatureState.portfolioList
);

export const selectPortfolioList = createSelector(
  selectPortfolioListState,
  portfolioListState => portfolioListState.portfolios || []
);

export const selectPortfolio = createSelector(
  selectPortfolioList,
  (allPortfolios: Portfolio[], props: { uid: string }): Portfolio | undefined =>
    allPortfolios.find(p => p.uid === props.uid)
);

export const selectActivePortfolioList = createSelector(
  selectPortfolioList,
  allPortfolios => allPortfolios.filter(p => !p.archived)
);

export const selectArchivedPortfolioList = createSelector(
  selectPortfolioList,
  allPortfolios => allPortfolios.filter(p => p.archived)
);

export const selectLoading = createSelector(
  selectPortfolioListState,
  portfolioListState => portfolioListState.loading
);

export const selectHasFetched = createSelector(
  selectPortfolioListState,
  portfolioListState => portfolioListState.hasFetched
);

export const selectError = createSelector(
  selectPortfolioListState,
  portfolioListState => portfolioListState.error
);
