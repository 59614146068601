// Deployed 'dev' environment

import { version } from './version';

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.FIREBASE_DATABASE_URL,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.FIREBASE_APP_ID,
    measurementId: process.env.FIREBASE_MEASUREMENT_ID,
  },
  regInterestFirebaseConfig: {
    apiKey: process.env.FIREBASE_REGINTEREST_API_KEY,
    authDomain: process.env.FIREBASE_REGINTEREST_AUTH_DOMAIN,
    projectId: process.env.FIREBASE_REGINTEREST_PROJECT_ID,
  },
  googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY,
  apiBase: process.env.API_BASE,
  googleSheetsTemplateUrl: process.env.GOOGLE_SHEETS_TEMPLATE_URL,
  useMockApi: false,
  enableRegisterInterest: process.env.ENABLE_REGISTER_INTEREST === 'true',
  appVersion: version.toString() + '-dev',
};
