import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import {
  map,
  catchError,
  exhaustMap,
  concatMap,
  take,
  switchMap,
  filter,
  tap,
} from 'rxjs/operators';
import { UserService } from '@app/core/services/user.service';
import { PortfoliosService } from '@app/portfolios/services/portfolios.service';
import { StoreState } from '../store.state';
import * as UserActions from './user.actions';
import * as UserSelectors from './user.selectors';
import {
  PortfolioListActions,
  PortfolioListSelectors,
} from '@app/portfolios/store/portfolio-list';
import { version } from 'src/environments/version';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GoogleAnalyticsService } from '@app/portfolios/services/google-analytics.service';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<StoreState>,
    private userService: UserService,
    private portfoliosService: PortfoliosService,
    private snackBar: MatSnackBar,
    private $gaService: GoogleAnalyticsService
  ) {}

  private openSnackBar(message: string): void {
    this.snackBar.open(message, 'OK', { duration: 16000 });
  }

  fetchUserInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UserActions.authService.fetchUserInfo,
        UserActions.portfolioLayoutPage.fetchUserInfo
      ),

      // Combine with store
      concatMap(() =>
        this.store$.pipe(select(UserSelectors.selectHasFetched), take(1))
      ),

      exhaustMap(hasFetched =>
        // Check if already loaded
        hasFetched
          ? EMPTY
          : this.userService.fetchUserInfo().pipe(
              map(user =>
                UserActions.usersApi.fetchUserInfoSuccess({
                  isAdmin: !!user.is_admin,
                  isSampleEnabled: !!user.sample_enabled,
                  isSampleCreated: !!user.sample_created,
                  appVersion: user.app_version,
                  userId: user.user_id,
                })
              ),
              catchError(error =>
                of(UserActions.usersApi.fetchUserInfoFailed({ error }))
              )
            )
      )
    )
  );

  createSamplePortfolio$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.usersApi.fetchUserInfoSuccess),

      // Only continue if the user is allowed the sample and it is not created yet
      tap(action => {
        console.log('MIE version:: ', action.appVersion);
        if (action.appVersion !== version) {
          this.openSnackBar(
            'A new version is available, please reload the site.'
          );
        }
      }),

      tap(action => {
        this.$gaService.setUser(action.userId);
      }),

      // Only continue if the user is allowed the sample and it is not created yet
      exhaustMap(action =>
        action.isSampleEnabled && !action.isSampleCreated ? of(action) : EMPTY
      ),

      exhaustMap(() =>
        this.portfoliosService.createSamplePortfolio().pipe(
          // If portfolio list is currently loading, wait until finished
          // before adding newly created sample portfolio to the list
          switchMap(portfolio =>
            this.store$.pipe(
              select(PortfolioListSelectors.selectLoading),
              filter(isLoading => !isLoading), // Wait for loading to be 'false'
              take(1),
              map(() => portfolio)
            )
          ),
          switchMap(portfolio => [
            PortfolioListActions.userEffects.createPortfolio({ portfolio }),
            UserActions.userEffects.setPortfolioSampleEnabled(),
          ]),
          catchError(() => EMPTY)
        )
      )
    )
  );

  // checkVersion$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(UserActions.usersApi.fetchUserInfoSuccess),

  //   ));
}
