import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface UserResponse {
  is_admin: boolean;
  sample_enabled: boolean;
  sample_created: boolean;
  app_version: string;
  user_id: string;
}

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(
    private http: HttpClient,
    @Inject('API_BASE') private apiBase: string
  ) {}

  wake(): Observable<UserResponse> {
    return this.http.get<UserResponse>(`${this.apiBase}users/wake/`);
  }

  fetchUserInfo(): Observable<UserResponse> {
    return this.http.get<UserResponse>(`${this.apiBase}users/info/`);
  }
}
