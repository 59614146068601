import { createAction, props } from '@ngrx/store';
import { Portfolio } from '@app/portfolios/models/portfolio.model';

export const portfolioListPage = {
  fetchPortfolioList: createAction(
    '[Portfolio list page] Fetch portfolio list'
  ),
  exportPortfolio: createAction(
    '[Portfolio list page] Export portfolio',
    props<{ uid: string; risk?: string }>()
  ),
  duplicatePortfolio: createAction(
    '[Portfolio list page] Duplicate portfolio',
    props<{ uid: string }>()
  ),
  renamePortfolio: createAction(
    '[Portfolio list page] Rename portfolio (portfolio list)',
    props<{ uid: string }>()
  ),
  archivePortfolio: createAction(
    '[Portfolio list page] Archive portfolio',
    props<{ uid: string }>()
  ),
  unarchivePortfolio: createAction(
    '[Portfolio list page] Unarchive portfolio',
    props<{ uid: string }>()
  ),
  deletePortfolio: createAction(
    '[Portfolio list page] Delete portfolio',
    props<{ uid: string; silent: boolean }>()
  ),
  deleteAllArchivedPortfolios: createAction(
    '[Portfolio list page] Delete all archived portfolios'
  ),
};

export interface ExportOptions {
  uid: string;
  risk?: string;
  sourceUid?: string;
}

export const portfolioDetailsPage = {
  exportPortfolio: createAction(
    '[Portfolio details page] Export portfolio',
    props<ExportOptions>()
  ),
};

export const fibreListPage = {
  exportPortfolio: createAction(
    '[Fibre list page] Export portfolio',
    props<ExportOptions>()
  ),
};

export const fibreDetailsPage = {
  exportPortfolio: createAction(
    '[Fibre list page] Export portfolio',
    props<ExportOptions>()
  ),
};

export const portfoliosAPI = {
  fetchPortfolioListSuccess: createAction(
    '[Portfolios API] Fetch portfolio list success',
    props<{ portfolios: Portfolio[] }>()
  ),
  fetchPortfolioListFailed: createAction(
    '[Portfolios API] Fetch portfolio list failed',
    props<{ error: string }>()
  ),
  exportPortfolioSuccess: createAction(
    '[Portfolios API] Export portfolio success'
  ),
  exportPortfolioFailed: createAction(
    '[Portfolios API] Export portfolio failed',
    props<{ error: string }>()
  ),
  duplicatePortfolioSuccess: createAction(
    '[Portfolios API] Duplicate portfolio success',
    props<{ portfolio: Portfolio }>()
  ),
  duplicatePortfolioFailed: createAction(
    '[Portfolios API] Duplicate portfolio failed',
    props<{ error: string }>()
  ),
  renamePortfolioSuccess: createAction(
    '[Portfolios API] Rename portfolio success',
    props<{ portfolio: Portfolio }>()
  ),
  renamePortfolioFailed: createAction(
    '[Portfolios API] Rename portfolio failed',
    props<{ error: string }>()
  ),
  archivePortfolioSuccess: createAction(
    '[Portfolios API] Archive portfolio success',
    props<{ portfolio: Portfolio }>()
  ),
  archivePortfolioFailed: createAction(
    '[Portfolios API] Archive portfolio failed',
    props<{ error: string }>()
  ),
  unarchivePortfolioSuccess: createAction(
    '[Portfolios API] Unarchive portfolio success',
    props<{ portfolio: Portfolio }>()
  ),
  unarchivePortfolioFailed: createAction(
    '[Portfolios API] Unarchive portfolio failed',
    props<{ error: string }>()
  ),
  deletePortfolioSuccess: createAction(
    '[Portfolios API] Delete portfolio success',
    props<{ uid: string }>()
  ),
  deletePortfolioFailed: createAction(
    '[Portfolios API] Delete portfolio failed',
    props<{ error: string }>()
  ),
  deleteAllArchivedPortfoliosSuccess: createAction(
    '[Portfolios API] Delete all archived portfolios success'
  ),
  deleteAllArchivedPortfoliosFailed: createAction(
    '[Portfolios API] Delete all archived portfolios failed',
    props<{ error: string }>()
  ),
};

export const portfolioDetailsEffects = {
  updateFibreCounts: createAction(
    '[Portfolio details effects] Update fibre counts (portfolio list)',
    props<{ portfolio: Portfolio }>()
  ),
};

export const uploadEffects = {
  createPortfolio: createAction(
    '[Upload effects] Create portfolio',
    props<{ portfolio: Portfolio }>()
  ),
};

export const userEffects = {
  createPortfolio: createAction(
    '[User effects] Create portfolio',
    props<{ portfolio: Portfolio }>()
  ),
};

export const authEffects = {
  reset: createAction('[Auth effects] Reset portfolio list state'),
};
