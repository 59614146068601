import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

// The value should match the icon filename in the '/assets/icons/' directory
export enum CustomMatIcons {
  ARROW_DOWN = 'arrow-down',
  EXPORT = 'export',
  PORTFOLIO_AVATAR = 'portfolio-avatar',
  QUANTITY = 'quantity',
  BIODIVERSITY = 'biodiversity',
  AIR_POLLUTION = 'air-pollution',
  CLIMATE = 'climate',
  FOREST = 'forest',
  WATER = 'water',
  WARNING = 'warning_icon_outline', // for some reason outline doesn't work on this icon
}

@Injectable({ providedIn: 'root' })
export class IconService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}

  loadCustomMatIcons(): void {
    Object.values(CustomMatIcons).forEach(icon => {
      this.matIconRegistry.addSvgIcon(
        icon,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `/assets/icons/${icon}.svg`
        )
      );
    });
  }
}
