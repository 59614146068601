import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { LayoutModule } from '@angular/cdk/layout';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';

import { firebase, firebaseui, FirebaseUIModule } from 'firebaseui-angular';
import { CoreModule } from './core/core.module';
import { StoreModule } from './store/store.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { appRoutes } from './app.routes';
import { environment } from 'src/environments/environment';
import { TourModule } from 'ngx-ui-tour-core';
import { buildSpecificModules } from './build-specific';
import { MOCK_API_BASE } from './app.config';
import { GoogleAnalyticsService } from './portfolios/services/google-analytics.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TourModule.forRoot(),
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled',
      relativeLinkResolution: 'legacy',
    }),
    LayoutModule,
    CoreModule,

    StoreModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
    FormsModule,
    MatSnackBarModule,
    FirebaseUIModule.forRoot({
      signInFlow: 'popup',
      signInOptions: [
        {
          fullLabel: 'Continue with Google',
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        },
        {
          requireDisplayName: false,
          fullLabel: 'Sign in/up with email',
          provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        },
      ],
      tosUrl:
        'https://textileexchange.org/terms-conditions-and-privacy-policy/',
      privacyPolicyUrl:
        'https://textileexchange.org/terms-conditions-and-privacy-policy/',
      credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    buildSpecificModules, // Import this last
  ],
  providers: [
    {
      provide: 'API_BASE',
      useValue: environment.useMockApi ? MOCK_API_BASE : environment.apiBase,
    },
    GoogleAnalyticsService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
