import { createFeatureSelector } from '@ngrx/store';
import { PortfolioListState } from './portfolio-list';
import { PortfolioDetailsState } from './portfolio-details';
import { FibreListState } from './fibre-list';
import { FibreDetailsState } from './fibre-details';
import { UploadState } from './upload';
import { MapState } from './map';

export interface PortfoliosStoreState {
  portfolioList: PortfolioListState;
  portfolioDetails: PortfolioDetailsState;
  fibreList: FibreListState;
  fibreDetails: FibreDetailsState;
  upload: UploadState;
  map: MapState;
}

export const PORTFOLIOS_FEATURE_KEY = 'portfolios';

export const selectPortfoliosFeatureState = createFeatureSelector<PortfoliosStoreState>(
  PORTFOLIOS_FEATURE_KEY
);
