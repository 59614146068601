import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { firebase } from 'firebaseui-angular';
import { combineLatest, of } from 'rxjs';
import { map, switchMap, filter, take, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { StoreState } from '@app/store/store.state';
import { AuthActions } from '@app/store/auth';
import { UserActions, UserSelectors } from '@app/store/user';
import { LOCAL_STORAGE_KEY_PORTFOLIO_UPLOAD_PAGE_ONBOARDING } from '@app/portfolios/portfolios.config';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private user$ = this.afAuth.authState;

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private store: Store<StoreState>
  ) {}

  isSignedIn$ = this.user$.pipe(map(user => !!user));
  isEmailVerified$ = this.user$.pipe(map(user => !!user?.emailVerified));
  isWhitelisted$ = this.isSignedIn$.pipe(
    switchMap(
      isSignedIn =>
        isSignedIn
          ? this.store.pipe(select(UserSelectors.selectHasFetched)).pipe(
              tap(hasFetched => {
                if (!hasFetched) {
                  // If not yet fetched, fetch user info in order to get whitelisted status
                  // 'isWhitelisted$' can be subscribed to externally to AuthService so need
                  // to trigger a refetch just in case
                  this.store.dispatch(UserActions.authService.fetchUserInfo());
                }
              }),
              filter(hasFetched => hasFetched),
              switchMap(() =>
                this.store.pipe(select(UserSelectors.selectIsWhitelisted))
              )
            )
          : of(false) // If not signed in, then whitelisted status will always be false
    )
  );

  token$ = this.user$.pipe(
    switchMap(user => (user ? user?.getIdToken() : of(null)))
  );
  email$ = this.user$.pipe(map(user => user?.email));

  signIn(): void {
    this.store.dispatch(AuthActions.authService.signIn());
    this.unlinkEmailProvider();
    this.handleRedirects();
  }

  signOut(redirect = true): void {
    this.afAuth.signOut().then(() => {
      this.store.dispatch(AuthActions.authService.signOut());
      if (redirect) {
        this.router.navigateByUrl('/signin');
      }
    });
  }

  sendEmailVerification(): void {
    this.user$.pipe(take(1)).subscribe(user => {
      user?.sendEmailVerification();
    });
  }

  private unlinkEmailProvider(): void {
    this.user$.pipe(take(1)).subscribe(user => {
      const hasGoogleProvider = user?.providerData.find(
        p => p?.providerId === firebase.auth.GoogleAuthProvider.PROVIDER_ID
      );
      const hasEmailProvider = user?.providerData.find(
        p => p?.providerId === firebase.auth.EmailAuthProvider.PROVIDER_ID
      );
      if (user?.providerId === firebase.auth.GoogleAuthProvider.PROVIDER_ID) {
        if (hasGoogleProvider && hasEmailProvider) {
          user.unlink(firebase.auth.EmailAuthProvider.PROVIDER_ID);
        }
      }
    });
  }

  private handleRedirects(): void {
    combineLatest([
      this.isSignedIn$,
      this.isEmailVerified$,
      this.isWhitelisted$,
    ])
      .pipe(take(1))
      .subscribe(([isSignedIn, isEmailVerified, isWhitelisted]) => {
        if (!isSignedIn) {
          this.router.navigateByUrl('/signin');
        } else if (!isWhitelisted) {
          this.router.navigateByUrl('/signin/verify-domain');
        } else if (!isEmailVerified) {
          this.sendEmailVerification();
          this.router.navigateByUrl('/signin/verify-email');
        } else {
          this.goToStartPage();
        }
      });
  }

  private async goToStartPage(): Promise<void> {
    const hasUserSeenOnboarding =
      localStorage.getItem(
        LOCAL_STORAGE_KEY_PORTFOLIO_UPLOAD_PAGE_ONBOARDING
      ) === 'true';
    if (hasUserSeenOnboarding) {
      this.router.navigate(['portfolios']);
    } else {
      // direct to portfolios/upload
      this.router.navigate(['portfolios', 'create']);
    }
  }
}
