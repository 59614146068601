import { NgModule, Optional, SkipSelf } from '@angular/core';
import { StoreModule as NgRxStoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './auth';
import { UserEffects, userReducer } from './user';

@NgModule({
  imports: [
    NgRxStoreModule.forRoot({
      user: userReducer,
    }),
    EffectsModule.forRoot([AuthEffects, UserEffects]),
  ],
})
export class StoreModule {
  constructor(@Optional() @SkipSelf() parentModule: StoreModule) {
    if (parentModule) {
      throw new Error(
        'StoreModule is already loaded. Import it in the AppModule only.'
      );
    }
  }
}
