import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import * as AuthActions from './auth.actions';
import { UserActions } from '@app/store/user';
import { PortfolioListActions } from '@app/portfolios/store/portfolio-list';
import { PortfolioDetailsActions } from '@app/portfolios/store/portfolio-details';
import { FibreListActions } from '@app/portfolios/store/fibre-list';
import { FibreDetailsActions } from '@app/portfolios/store/fibre-details';

@Injectable()
export class AuthEffects {
  constructor(private actions$: Actions) {}

  signIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.authService.signIn),
      map(() => UserActions.authEffects.fetchUserInfo())
    )
  );

  signOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.authService.signOut),
      switchMap(() => [
        UserActions.authEffects.reset(),
        PortfolioListActions.authEffects.reset(),
        PortfolioDetailsActions.authEffects.reset(),
        FibreListActions.authEffects.reset(),
        FibreDetailsActions.authEffects.reset(),
      ])
    )
  );
}
