import { createAction, props } from '@ngrx/store';

export const authService = {
  fetchUserInfo: createAction('[Auth service] Fetch user info'),
};

export const authEffects = {
  fetchUserInfo: createAction('[Auth effects] Fetch user info'),
  reset: createAction('[Auth effects] Reset user state'),
};

export const portfolioLayoutPage = {
  fetchUserInfo: createAction('[Portfolio layout page] Fetch user info'),
};

export const usersApi = {
  fetchUserInfoSuccess: createAction(
    '[Users API] Fetch user info success',
    props<{
      isAdmin: boolean;
      isSampleEnabled: boolean;
      isSampleCreated: boolean;
      appVersion: string;
      userId: string;
    }>()
  ),
  fetchUserInfoFailed: createAction(
    '[Users API] Fetch user info failed',
    props<{ error: string }>()
  ),
};

export const userEffects = {
  setPortfolioSampleEnabled: createAction(
    '[User effects] Set portfolio sample enabled'
  ),
};
