import { Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthGuard } from './core/guards/auth.guard';

export const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'about',
    data: { pageTitle: 'About' },
    loadChildren: () => import('./about/about.module').then(m => m.AboutModule),
  },
  {
    path: 'help',
    data: { pageTitle: 'Help' },
    loadChildren: () => import('./help/help.module').then(m => m.HelpModule),
  },
  {
    path: 'methodology',
    data: { pageTitle: 'Methodology' },
    loadChildren: () =>
      import('./methodology/methodology.module').then(m => m.MethodologyModule),
  },
  {
    // Disallow route and redirect if in 'register interest' mode
    path: 'signin',
    data: { pageTitle: 'Signin' },
    loadChildren: () =>
      import('./signin/signin.module').then(m => m.SignInModule),
  },
  {
    path: 'portfolios',
    data: { pageTitle: 'Portfolios' },
    loadChildren: () =>
      import('./portfolios/portfolios.module').then(m => m.PortfoliosModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'error',
    data: { pageTitle: 'Error' },
    loadChildren: () => import('./error/error.module').then(m => m.ErrorModule),
  },
  {
    path: '**',
    data: { pageTitle: '404' },
    loadChildren: () =>
      import('./not-found/not-found.module').then(m => m.NotFoundModule),
  },
];
