import { createAction, props } from '@ngrx/store';
import { Fibre, FibreWrapper } from '@app/portfolios/models/fibre.model';
import {
  RiskSourceNode,
  RiskTreeNode,
} from '@app/portfolios/models/risk.model';

export const fibreDetailsPage = {
  fetchFibreDetails: createAction(
    '[Fibre details page] Fetch fibre details',
    props<{ portfolioId: string; fibreId: string }>()
  ),
  expandRisk: createAction(
    '[Fibre details page] Expand risk',
    props<{ key: string }>()
  ),
  collapseRisk: createAction(
    '[Fibre details page] Collapse risk',
    props<{ key: string }>()
  ),
  showRiskSources: createAction(
    '[Fibre details page] Show risk sources',
    props<{ risk: RiskTreeNode }>()
  ),
  showFibreAlternatives: createAction(
    '[Fibre details page] Show fibre alternatives'
  ),
  removeFibreToCompare: createAction(
    '[Fibre details page] Remove fibre to compare',
    props<{ fibreId: string }>()
  ),
  replaceFibre: createAction(
    '[Fibre details page] Replace fibre',
    props<{
      portfolioId: string;
      fibreId: string;
      replacementFibre: Fibre;
    }>()
  ),
  reinstateOriginalFibre: createAction(
    '[Fibre details page] Reinstate original fibre',
    props<{ portfolioId: string; fibreId: string }>()
  ),
};

export const selectFibreToCompareDialog = {
  addFibreToCompare: createAction(
    '[Select fibre to compare dialog] Add fibre to compare',
    props<{ fibre: Fibre }>()
  ),
};

export const fibreDetailsEffects = {
  fetchFibreAlternatives: createAction(
    '[Fibre details effects] Fetch fibre alternatives',
    props<{ portfolioId: string; fibreId: string }>()
  ),
};

export const fibresAPI = {
  fetchFibreDetailsSuccess: createAction(
    '[Fibres API] Fetch fibre details success',
    props<{ fibre: FibreWrapper }>()
  ),
  fetchFibreDetailsFailed: createAction(
    '[Fibres API] Fetch fibre details failed',
    props<{ error: string }>()
  ),
  fetchFibreAlternativesSuccess: createAction(
    '[Fibres API] Fetch fibre alternatives success',
    props<{ fibres: Fibre[] }>()
  ),
  fetchFibreAlternativesFailed: createAction(
    '[Fibres API] Fetch fibre alternatives failed',
    props<{ error: string }>()
  ),
  replaceFibreSuccess: createAction(
    '[Fibres API] Replace fibre success',
    props<{ fibreId: string; replacementFibre: Fibre }>()
  ),
  replaceFibreFailed: createAction(
    '[Fibres API] Replace fibre failed',
    props<{ error: string }>()
  ),
  reinstateOriginalFibreSuccess: createAction(
    '[Fibres API] Reinstate original fibre success',
    props<{ fibreId: string }>()
  ),
  reinstateOriginalFibreFailed: createAction(
    '[Fibres API] Reinstate original fibre failed',
    props<{ error: string }>()
  ),
};

export const fibreListEffects = {
  reinstateOriginalFibre: createAction(
    '[Fibres list effects] Reinstate original fibre',
    props<{ fibreId: string }>()
  ),
};

export const authEffects = {
  reset: createAction('[Auth effects] Reset fibre details state'),
};
