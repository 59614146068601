import { createReducer, on } from '@ngrx/store';
import * as UserActions from './user.actions';

export interface UserState {
  isWhitelisted: boolean;
  isAdmin: boolean;
  isSampleEnabled: boolean;
  loading: boolean;
  hasFetched: boolean;
  error: string | null;
}

export const initialState: UserState = {
  isWhitelisted: false,
  isAdmin: false,
  isSampleEnabled: false,
  loading: false,
  hasFetched: false,
  error: null,
};

export const userReducer = createReducer(
  initialState,
  on(UserActions.authEffects.reset, () => ({
    ...initialState,
  })),
  on(
    UserActions.authService.fetchUserInfo,
    UserActions.authEffects.fetchUserInfo,
    UserActions.portfolioLayoutPage.fetchUserInfo,
    state => ({
      ...state,
      loading: state.hasFetched ? false : true,
      error: null,
    })
  ),
  on(UserActions.userEffects.setPortfolioSampleEnabled, state => ({
    ...state,
    isSampleEnabled: true,
  })),
  on(
    UserActions.usersApi.fetchUserInfoSuccess,
    (state, { isAdmin, isSampleEnabled }) => ({
      ...state,
      isWhitelisted: true, // If success then user is whitelisted
      isAdmin,
      isSampleEnabled,
      loading: false,
      hasFetched: true,
      error: null,
    })
  ),
  on(UserActions.usersApi.fetchUserInfoFailed, (state, { error }) => ({
    ...state,
    isWhitelisted: false,
    isAdmin: false,
    isSampleEnabled: false,
    loading: false,
    hasFetched: true,
    error,
  }))
);
