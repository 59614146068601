import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    request: HttpRequest<any>,
    next: HttpHandler
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<HttpEvent<any>> {
    return this.authService.token$.pipe(
      take(1),
      mergeMap(token => {
        const authRequest = !!token
          ? request.clone({
              // eslint-disable-next-line @typescript-eslint/naming-convention
              setHeaders: { Authorization: `Bearer ${token}` },
            })
          : request;
        return next.handle(authRequest);
      })
    );
  }
}
