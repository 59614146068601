import { HttpClient, HttpEvent, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  Portfolio,
  PortfolioPriorityFibre,
  PortfolioOverviewItem,
  PortfolioPriorityFiberByCat,
  PortfolioPriorityFiberByType,
} from '../models/portfolio.model';
import { ExportOptions } from '../store/portfolio-list/portfolio-list.actions';

interface PortfolioListResponse {
  total: number;
  results: Portfolio[];
}

@Injectable({ providedIn: 'root' })
export class PortfoliosService {
  constructor(
    private http: HttpClient,
    @Inject('API_BASE') private apiBase: string
  ) {}

  fetchPortfolioList(
    limit?: number,
    offset?: number
  ): Observable<PortfolioListResponse> {
    let params = new HttpParams();
    if (limit) {
      params = params.append('limit', String(limit));
    }
    if (offset) {
      params = params.append('offset', String(offset));
    }

    return this.http.get<PortfolioListResponse>(`${this.apiBase}portfolios/`, {
      params,
    });
  }

  fetchPortfolio(uid: string): Observable<Portfolio> {
    return this.http.get<Portfolio>(`${this.apiBase}portfolios/${uid}/`);
  }

  fetchPriorityFibres(uid: string): Observable<PortfolioPriorityFiberByCat[]> {
    return this.http.get<PortfolioPriorityFiberByCat[]>(
      `${this.apiBase}portfolios/${uid}/priorities?limit=5`
    );
  }

  fetchPriorityFibresByCat(
    uid: string
  ): Observable<PortfolioPriorityFiberByType[]> {
    return this.http.get<PortfolioPriorityFiberByType[]>(
      `${this.apiBase}portfolios/${uid}/priorities/category?limit=5`
    );
  }

  fetchOverview(uid: string): Observable<PortfolioOverviewItem[]> {
    return this.http.get<PortfolioOverviewItem[]>(
      `${this.apiBase}portfolios/${uid}/overview/`
    );
  }

  exportPortfolio(args: ExportOptions): Observable<Blob> {
    let url = `${this.apiBase}portfolios/${args.uid}/export/`;
    if (args.risk) {
      url += `?risk=${args.risk}`;
    }
    if (args.sourceUid) {
      url += `?source_uid=${args.sourceUid}`;
    }
    return this.http
      .get<string>(url, {
        // Backend send as a stream so need to receive as 'text' response instead of 'json'
        // 'json' will fail as the combined chunks are not valid json
        responseType: 'text' as any, // eslint-disable-line @typescript-eslint/no-explicit-any
      })
      .pipe(
        /* Split lines into json objects because the text response contains
           stringified json rows without a surrounding array or comma separators, e.g.
           '{ "one": "ONE", "two": "TWO" }
            { "three": "THREE", "four": "FOUR" }
            { "five": "FIVE", "six": "SIX" }'
        */
        map(res =>
          res
            .split(/\r?\n/)
            .filter(line => !!line)
            .map(line => JSON.parse(line))
        ),
        // Convert JSON objects to CSV
        map(jsonObjs => {
          const headers = Object.keys(jsonObjs[0]);
          const headerRow = headers.join(',');
          const rows = jsonObjs.map(row =>
            headers
              .map(fieldName =>
                JSON.stringify(row[fieldName], (_, value) =>
                  value === null ? '' : value
                )
              )
              .join(',')
          );

          const csv = [headerRow, ...rows].join('\n');
          return new Blob([csv]);
        })
      );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  uploadFile(file: File): Observable<HttpEvent<any>> {
    const formData = new FormData();
    formData.append('file', file, file.name);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.http.post<HttpEvent<any>>(
      `${this.apiBase}portfolios/upload/`,
      formData,
      {
        reportProgress: true,
        observe: 'events',
      }
    );
  }

  uploadFileForDuplicate(file: File): Observable<Portfolio> {
    const formData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post<Portfolio>(
      `${this.apiBase}portfolios/upload/`,
      formData
    );
  }

  renamePortfolio(uid: string, name: string): Observable<Portfolio> {
    return this.http.patch<Portfolio>(`${this.apiBase}portfolios/${uid}/`, {
      name,
    });
  }

  archivePortfolio(uid: string): Observable<Portfolio> {
    return this.http.get<Portfolio>(
      `${this.apiBase}portfolios/${uid}/archive/`
    );
  }

  unarchivePortfolio(uid: string): Observable<Portfolio> {
    return this.http.get<Portfolio>(
      `${this.apiBase}portfolios/${uid}/unarchive/`
    );
  }

  deletePortfolio(uid: string): Observable<any> {
    return this.http.delete<any>(`${this.apiBase}portfolios/${uid}/`);
  }

  deleteAllArchivedPortfolios(): Observable<any> {
    return this.http.delete<any>(`${this.apiBase}portfolios/archived/`);
  }

  createSamplePortfolio(): Observable<Portfolio> {
    return this.http.get<Portfolio>(`${this.apiBase}portfolios/sample/`);
  }
}
