import { Component, OnInit } from '@angular/core';
import { IconService } from './services/icons.service';
import {
  ActivatedRoute,
  ChildActivationEnd,
  NavigationEnd,
  Router,
} from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
})
export class CoreComponent {
  constructor(private iconService: IconService) {
    this.iconService.loadCustomMatIcons();
  }
}
