import { createSelector } from '@ngrx/store';
import { StoreState } from '../store.state';

export const selectUserState = (state: StoreState) => state.user;

export const selectLoading = createSelector(
  selectUserState,
  userState => userState.loading
);

export const selectHasFetched = createSelector(
  selectUserState,
  userState => userState.hasFetched
);

export const selectError = createSelector(
  selectUserState,
  userState => userState.error
);

export const selectIsWhitelisted = createSelector(
  selectUserState,
  userState => userState.isWhitelisted
);

export const selectIsAdmin = createSelector(
  selectUserState,
  userState => userState.isAdmin
);

export const selectIsSampleEnabled = createSelector(
  selectUserState,
  userState => userState.isSampleEnabled
);
