import { createAction, props } from '@ngrx/store';
import {
  Fibre,
  FibreSortKey,
  FibreSortOrder,
  FibreWrapper,
} from '@app/portfolios/models/fibre.model';

export const fibreListPage = {
  fetchFibreList: createAction(
    '[Fibre list page] Fetch fibre list',
    props<{ portfolioId: string }>()
  ),
  setSortKey: createAction(
    '[Fibre list page] Set fibre list sort key',
    props<{ sortKey: FibreSortKey }>()
  ),
  setSortOrder: createAction(
    '[Fibre list page] Set fibre list sort order',
    props<{ sortOrder: FibreSortOrder }>()
  ),
  setFiberCategory: createAction(
    '[Fibre list page] Set fibre category',
    props<{ fiberCategory: string }>()
  ),
  toggleSortOrder: createAction(
    '[Fibre list page] Toggle fibre list sort order'
  ),
  setActiveRisk: createAction(
    '[Fibre list page] Set active risk',
    props<{ key: string }>()
  ),
  reinstateOriginalFibre: createAction(
    '[Fibre list page] Reinstate original fibre',
    props<{ portfolioId: string; fibreId: string }>()
  ),
  goToFirstPage: createAction('[Fibre list page] Go to first page'),
  goToLastPage: createAction('[Fibre list page] Go to last page'),
  goToPrevPage: createAction('[Fibre list page] Go to prev page'),
  goToNextPage: createAction('[Fibre list page] Go to next page'),
};

export const fibresAPI = {
  fetchFibreListSuccess: createAction(
    '[Fibres API] Fetch fibre list success',
    props<{
      portfolioId: string;
      fibres: FibreWrapper[];
    }>()
  ),
  fetchFibreListFailed: createAction(
    '[Fibres API] Fetch fibre list failed',
    props<{ error: string }>()
  ),
  reinstateOriginalFibreSuccess: createAction(
    '[Fibres API] Reinstate original fibre success',
    props<{ fibreId: string }>()
  ),
  reinstateOriginalFibreFailed: createAction(
    '[Fibres API] Reinstate original fibre failed',
    props<{ error: string }>()
  ),
};

export const fibreDetailsEffects = {
  replaceFibre: createAction(
    '[Fibres details effects] Replace fibre',
    props<{ fibreId: string; replacementFibre: Fibre }>()
  ),
  reinstateOriginalFibre: createAction(
    '[Fibres details effects] Reinstate original fibre',
    props<{ fibreId: string }>()
  ),
};

export const authEffects = {
  reset: createAction('[Auth effects] Reset fibre list state'),
};
