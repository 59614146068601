// Mock api
export const MOCK_API_BASE = 'mock-api/';

// Breakpoints
export const BREAKPOINT_MOBILE = 375;
export const BREAKPOINT_TABLET = 768;
export const BREAKPOINT_DESKTOP = 1024;
export const BREAKPOINT_LARGE_DESKTOP = 1280;
export const BREAKPOINT_EXTRA_LARGE_DESKTOP = 1440;

// Media queries
export const MEDIA_QUERY_MOBILE_UP = `(min-width: ${BREAKPOINT_MOBILE}px)`;
export const MEDIA_QUERY_TABLET_UP = `(min-width: ${BREAKPOINT_TABLET}px)`;
export const MEDIA_QUERY_DESKTOP_UP = `(min-width: ${BREAKPOINT_DESKTOP}px)`;
export const MEDIA_QUERY_LARGE_DESKTOP_UP = `(min-width: ${BREAKPOINT_LARGE_DESKTOP}px)`;
export const MEDIA_QUERY_EXTRA_LARGE_DESKTOP_UP = `(min-width: ${BREAKPOINT_EXTRA_LARGE_DESKTOP}px)`;

export const MEDIA_QUERY_MOBILE_DOWN = `(max-width: ${
  BREAKPOINT_MOBILE - 1
}px)`;
export const MEDIA_QUERY_TABLET_DOWN = `(max-width: ${
  BREAKPOINT_TABLET - 1
}px)`;
export const MEDIA_QUERY_DESKTOP_DOWN = `(max-width: ${
  BREAKPOINT_DESKTOP - 1
}px)`;
export const MEDIA_QUERY_LARGE_DESKTOP_DOWN = `(max-width: ${
  BREAKPOINT_LARGE_DESKTOP - 1
}px)`;
export const MEDIA_QUERY_EXTRA_LARGE_DESKTOP_DOWN = `(max-width: ${
  BREAKPOINT_EXTRA_LARGE_DESKTOP - 1
}px)`;
