import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

declare let gtag: any;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor() {}

  /**
   * Checks if the GA script was loaded.
   */
  private useGA(): boolean {
    return (
      environment.firebaseConfig.measurementId !== undefined &&
      typeof gtag !== undefined
    );
  }

  /**
   * Sends the page view to GA.
   *
   * @param page_title and page_location.
   */
  sendPageView(args: { page_title: string; page_location: string }) {
    if (this.useGA()) {
      gtag('event', 'page_view', args);
    }
  }

  /**
   * Sets the user. We cannot use an email due to GDPR
   */
  setUser(user_id: string) {
    if (this.useGA()) {
      gtag('set', {
        user_id,
      });
    }
  }

  event(eventName: string, eventData: any) {
    if (this.useGA()) {
      gtag('event', eventName, eventData);
    }
  }
}
