/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  ngOnInit(): void {
    this.loadGA4();
  }

  loadGA4(): void {
    // dw there are issues with firebase and compatibility. We also need to take control so we
    // can anonymize the IP address. Easier just to do this manually

    // Get the Google Analytics measurement ID from the environment
    const googleAnalyticsId = environment.firebaseConfig.measurementId;

    // Load Google Analytics script
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`;
    document.head.appendChild(script);

    script.onload = () => {
      const w: any = window;
      w.dataLayer = w.dataLayer || [];
      function gtag(...arg: any[]) {
        w.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', googleAnalyticsId, { anonymize_ip: true });
      w.gtag = gtag;
    };
  }
}
